@import "../../../styles/base/base.scss";

.image-container {
  display: flex;
  flex-direction: column;
}

.message-image {
  padding: 6px 6px 0 6px;
  margin: 0;

  img {
    width: 100%;
    height: auto;
    max-height: 400px;
    max-width: 400px;
    border-radius: 4px;
    object-fit: cover;
  }
}

.ReactModal__Overlay--after-open {
	margin-top: 54px;
	height: calc(100% - 54px);
}
