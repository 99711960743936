@import "../../styles/base/base.scss";

.audio-animation {
  position: absolute;
  height: 210px;
  right: 15px;
  bottom: 15px;

  z-index: 999;

  width: 200px;
  height: 200px;
  background-image: radial-gradient(
    circle at 100% 100%,
    #eb55f3 15%,
    #f6cb5e 60%
  );
  border-top-left-radius: 86%;

  box-shadow: rgba(0, 0, 0, 0.75) 0px 5px 15px;
  color: #fff;

  span {
    word-wrap: break-word;
    word-break: break-word;
    text-shadow: 0.5px 0.5px 0.5px black;
  }

  @keyframes close {
    0% {
      height: 210px;
      width: 200px;
      opacity: 1;
    }

    100% {
      height: 0px;
      width: 0px;
      opacity: 0;
    }
  }

  .animate {
    animation: close 0.8s linear;
    animation-fill-mode: forwards;
  }

  .phrases-container {
    position: relative;
    display: flex;
    top: 50px;
    left: 20px;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .use-your-span {
      font-size: 32px;

      // animation: closeSpan 0.8s linear;
      animation-fill-mode: forwards;

      @keyframes closeSpan {
        0% {
          font-size: 32px;
          opacity: 1;
        }

        100% {
          font-size: 0px;
          opacity: 0;
        }
      }
    }
    .voice-span {
      font-size: 64px;
      line-height: 32px;

      // animation: closeSspan 0.8s linear;
      animation-fill-mode: forwards;

      @keyframes closeSspan {
        0% {
          font-size: 64px;
          opacity: 1;
        }

        100% {
          font-size: 0px;
          opacity: 0;
        }
      }
    }
  }
}

.footer-bar {
  align-items: center;
  height: 55px;
  justify-content: center;
  display: flex;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  color: $text-color;
  padding-bottom: 5px;
  font-size: 24px;
  // position: fixed;
  bottom: 0;
  z-index: 1;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  // background: $footer-background-color;

  &.namu {
    background-color: #fff;

    .conversation-container {
      border-radius: none !important;
      box-shadow: none !important;
    }
  }

  .conversation-compose {
    justify-content: center;
    display: flex;
    align-items: center;
    height: 50px;
    width: 100%;
    z-index: 2;
    margin: 0 16px;

    div,
    input {
      height: 100%;
    }

    .conversation-container {
      display: flex;
      align-items: center;

      background: $footer-background-color;
      border-radius: 3rem;
      box-shadow: $message__box-shadow;
      display: flex;
      justify-content: space-between;
      padding: 0 3px 0 15px;
      transition: width 2s ease-in-out;
      width: 90%;

      &__timer {
        align-items: center;
        color: $footer-color;
        display: flex;
      }

      &__input-text {
        background: none;
        width: 100%;
        border: none;
        color: $footer-color;
        font-size: 16px;
        outline: none;
        margin-left: 10px;

        &::placeholder {
          color: $footer-color;
          opacity: 0.6;
        }
      }

      &__input-file {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 auto;
        width: 48px;

        button {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          background: none;
          border: none;
          border-radius: 50%;
          width: 28px;
          height: 38px;

          &:hover {
            transition: background 0.5s;
            background: rgb(198 198 198 / 52%);
          }

          svg {
            font-size: 1.1rem;

            fill: $footer-color;
            opacity: 0.6;
          }
        }

        button:active {
          animation: focus 500ms ease-in-out;
          border-radius: 50%;
          transition: background 1s;
        }

        @keyframes focus {
          to {
            background: rgba(0, 0, 0, 0.548);
          }
        }
      }
    }

    .container-audio {
      display: flex;
      align-items: center;
      margin-left: 8px;

      p {
        margin: 0 8px;
        color: $text-color;
        font-size: 18px;
        width: 56px;
        text-align: center;
      }

      .audio-cancel {
        border: 2px solid #e74c3c;
        background-color: rgba(255, 255, 255, 0.25) !important;
        svg {
          fill: #e74c3c;
        }
      }

      .audio-send {
        border: 2px solid #2ecc71;
        background-color: rgba(255, 255, 255, 0.25) !important;
        svg {
          fill: #2ecc71;
        }
      }
    }

    .photo {
      flex: 0 0 auto;
      border-radius: 0 0 5px 0;
      text-align: center;
      position: relative;
      width: 48px;

      &:after {
        border-width: 0px 0 10px 10px;
        border-color: transparent transparent transparent #fff;
        border-style: solid;
        position: absolute;
        width: 0;
        height: 0;
        content: "";
        top: 0;
        right: -10px;
      }

      i {
        display: block;
        color: #7d8488;
        font-size: 24px;
        transform: translate(-50%, -50%);
        position: relative;
        top: 50%;
        left: 50%;
      }
    }

    .send {
      background: transparent;
      width: fit-content;
      height: fit-content;
      border: 0;
      cursor: pointer;
      flex: 0 0 auto;
      margin-left: 8px;
      padding: 0;
      position: relative;
      outline: none;

      .circle {
        background: $footer-btn-send-background-color;
        border-radius: 50%;
        color: $footer-btn-send-color;
        position: relative;
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

        &:hover {
          background-color: #37b062;
        }

        svg {
          width: 20px;
          height: 20px;
        }
      }

      .circle-disabled {
        background-color: #7f8c8d !important;
        cursor: default;
      }
    }

    .send.active {
      .circle {
        transition: background-color 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        background-color: #37b062;
        width: 56px;
        height: 56px;
      }
    }
  }
}

.suggestions-row {
  align-items: center;
  text-align: center;
  padding-bottom: 0px;
  // position: absolute;
  bottom: 55px;
  display: flex;
  overflow: auto;
  padding: 8px 0;
  width: 100%;

  &::-webkit-scrollbar {
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    margin-right: 8px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $background-color;
    border-radius: 20px;
  }

  .suggestion {
    flex: 0 0 auto;
    margin-left: 8px;
    margin-right: 8px;
    padding: 5px 15px 5px 15px;
    background-color: $background-color;
    border-bottom-color: black;
    border-radius: 16px;
    min-height: 38px;

    border-bottom-style: hidden;
    border-bottom-width: 0px;

    border-image-outset: 0;
    border-image-repeat: stretch;
    border-image-slice: 100%;
    border-image-width: 1;

    border-left-color: black;
    border-left-style: hidden;
    border-left-width: 0px;

    border-right-color: black;
    border-right-style: hidden;
    border-right-width: 0px;

    border-top-color: black;
    border-top-style: hidden;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 8px 16px 0px;
    box-sizing: border-box;
    color: $text-color;

    span {
      font-weight: bold;
      font-size: 16px;
      text-align: center;
    }
  }
}

.suggestions-fix {
  justify-content: center;
  align-items: center;
}
